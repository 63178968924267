import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { NftModelToCardData } from '../../lib/models/NftModel';
import { isOnMobile, isOnTablet, sleep } from '../../lib/Utils';
import ArrowLeft from '../icons/ArrowLeft';
import ArrowRight from '../icons/ArrowRight';
import { NftCard } from '../NftCard';
import { homeAuctionsListState } from '../../recoil/atoms';
import { connectNatServer, decodeNatMessage } from '../../lib/Nats';
import { configService } from '../../lib/services/ConfigService';
import { NewBidNotification } from '../../lib/models/GeneralModels';
import { storageService } from '../../lib/db/StorageService';

export const ActiveAuctionsRow = () => {
  const [homeAuctionsList, setHomeAuctionsList] = useRecoilState(
    homeAuctionsListState,
  );

  const { t } = useTranslation();

  const sliderRef = useRef<HTMLDivElement>(null);
  const nextSlide = () => {
    if (sliderRef) {
      sliderRef.current?.scrollBy(300, 0);
    }
  };

  const prevSlide = () => {
    if (sliderRef) {
      sliderRef.current.scrollBy(-330, 0);
    }
  };

  const natsListener = async () => {
    const nc = await connectNatServer();

    const topic = `${configService.getEnv() || 'staging'}.home`;
    // eslint-disable-next-line no-console
    console.log('HOME_TOPIC', topic);

    const sub = nc?.subscribe(topic);
    if (!sub) {
      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    for await (const m of sub) {
      const receivedPayload = decodeNatMessage(m.data) as NewBidNotification;
      const { nft } = receivedPayload.payload;

      // eslint-disable-next-line no-console
      console.log(`NEW_AUCTIONS_ROW_UPDATE: Price=> ${nft.price}`, { nft });
      await sleep(2_500);

      const loadedTopTradersData = await storageService.loadTopTraders();
      if (loadedTopTradersData && loadedTopTradersData?.auctions) {
        // eslint-disable-next-line no-console
        console.log('FRESHLY_LOADED_AUCTIONS', loadedTopTradersData?.auctions);

        setHomeAuctionsList(loadedTopTradersData?.auctions);
      }

      await sleep(2_500);
    }
  };

  useEffect(() => {
    natsListener();
  }, []);

  const onAuctionEnd = async (auction) => {
    const filter = homeAuctionsList.filter(
      (data) => data.baseID !== auction.baseID,
    );

    await sleep(4_000);

    setHomeAuctionsList(filter);
  };

  return (
    <div>
      {(homeAuctionsList.filter(
        (auction) => !dayjs().isAfter(auction.auctionEndedAt),
      ).length && (
        <>
          {' '}
          <div className="top-trader-title">{t('Hot Auctions ⏱')} </div>
          <div style={{ height: '8px' }} />{' '}
        </>
      )) ||
        null}
      <div className="nfts-filter-row-wrapper">
        <div className="nfts-top-trader-row">
          <div
            onKeyDown={prevSlide}
            role="presentation"
            tabIndex={-50}
            className="top-trader-arrow left-arrow"
            onClick={prevSlide}
            style={{
              display: `${
                homeAuctionsList.map((data) => NftModelToCardData(data))
                  .length <= 4 ||
                isOnMobile() ||
                isOnTablet()
                  ? 'none'
                  : 'block'
              }`,
            }}
          >
            <ArrowLeft />
          </div>
          <div
            onKeyDown={prevSlide}
            role="presentation"
            tabIndex={-50}
            className="top-trader-arrow right-arrow"
            onClick={nextSlide}
            style={{
              display: `${
                homeAuctionsList.map((data) => NftModelToCardData(data))
                  .length <= 4 ||
                isOnMobile() ||
                isOnTablet()
                  ? 'none'
                  : 'block'
              }`,
            }}
          >
            <ArrowRight />
          </div>
          <div ref={sliderRef} className="exclusive-drop-slider">
            {homeAuctionsList
              .filter((auction) => !dayjs().isAfter(auction.auctionEndedAt))
              .map((data) => NftModelToCardData(data))
              .map((data) => (
                <div key={data.nftUrlThumbnail} className="drop-slider">
                  <NftCard
                    className="w-310"
                    nftCardData={data}
                    onAuctionEnd={onAuctionEnd}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div style={{ height: '30px' }} />
    </div>
  );
};
