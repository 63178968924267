import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const EmptyNftListComponent = ({
  onExploreMarketClicked,
}: {
  onExploreMarketClicked: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="empty-nfts-results">
      <div className="empty-nfts-results-title">{t('No items found')}</div>
      <div className="can-create-or-buy-text">
        {t('You can create or buy some NFTs on marketplace')}
      </div>
      <div className="explore-markets-btn-div">
        <Button
          type="primary"
          className="explore-markets-btn"
          onClick={onExploreMarketClicked}
        >
          <span className="explore-markets-btn-txt">
            {t('Explore Marketplace')}
          </span>
        </Button>
      </div>
    </div>
  );
};
