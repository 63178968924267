import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UnderMaintenanceIcon } from '../../lib/Resources';

const UnderMaintenance: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="maintenance-div">
      <div className="maintenance-img-div">
        <img
          src={UnderMaintenanceIcon}
          alt="Under maintenance icon"
          height="100"
          width="100"
        />
      </div>
      <div className="empty-nfts-results-title">{t('Ongoing Maintenance')}</div>
      <div className="maintenance-description">
        {t(
          'We are having technical difficulties and working on fixing the issue.',
        )}
        {t('We are sorry for inconvenience. This might take few hours.')}
      </div>
    </div>
  );
};

export default UnderMaintenance;
