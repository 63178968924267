import { Col, Row } from 'antd';
import React from 'react';
import { middleEllipsis, truncate } from '../lib/Utils';
import { UserAccount } from '../lib/models/UserAccount';
import SmallProfile from './ui/SmallProfile';
import HrefLink from './ui/HrefLink';
import { VolumeTradedUSD } from './ui/VolumeTradedUSD';

export function TopTrader({
  trader,
  position,
  minAddressEllipsis = 3,
  volume,
}: {
  trader: UserAccount;
  position: number;
  minAddressEllipsis?: number;
  volume?: number;
}) {
  return (
    <HrefLink
      className="creator-container"
      href={`/creators/${trader.walletAddress}`}
    >
      <Row>
        <div className="trader-position">{position}.</div>

        <SmallProfile user={trader} />

        <Col
          style={{
            paddingTop: '8px',
            paddingBottom: '8px',
            marginLeft: '6px',
          }}
        >
          <div className="owner-username top-trader-name">
            {trader.username
              ? truncate(trader.username, 15)
              : middleEllipsis(trader.walletAddress, minAddressEllipsis)}
          </div>
          <div className="owner-label">
            <div
              style={{
                fontFamily: 'IBM Plex Sans',
                fontStyle: 'normal',
                fontWeight: 500,
              }}
            >
              <VolumeTradedUSD amount={volume || trader.USDSalesTotalAmount} />
            </div>
          </div>
        </Col>
      </Row>
    </HrefLink>
  );
}
