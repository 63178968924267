import React, { useEffect, useRef, useState } from 'react';
import { List, Spin } from 'antd';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { NftCard } from '../components/NftCard';
import {
  allCollectionDataState,
  allNftDataState,
  allTopTradersState,
  currentSelectedCategoryState,
  dropDetailDataState,
  listLoadingState,
  searchModeState,
  searchStoreState,
  sorterQueryDropRecoilState,
  sorterQueryRecoilState,
} from '../recoil/atoms';
import { storageService } from '../lib/db/StorageService';
import { isOnMobile, isOnTablet, processCategory, sleep } from '../lib/Utils';
import { TopTrader } from '../components/TopTrader';
import { InitialTopTradersLoad } from '../recoil/models';
import { FilterIcon } from '../lib/Resources';
import { FilterModal } from '../components/ui/FilterModal';
import {
  CollectionModelToCardData,
  NftModelToCardData,
  SpecialDropToDropCardData,
} from '../lib/models/NftModel';
import { ExploreTitle } from '../components/ui/ExploreTitle';
import { EmptyNftListComponent } from '../components/ui/EmptyNftList';
import { DropCard } from '../components/ui/SpecialDropCard';
import ArrowLeft from '../components/icons/ArrowLeft';
import ArrowRight from '../components/icons/ArrowRight';
import { CollectionCard } from '../components/CollectionCard';
import ListViewBuilder from '../components/ListViewBuilder';
import UnderMaintenance from '../components/ui/UnderMaintenance';
import { FilterCollectionModal } from '../components/ui/FilterCollectionModal';
import { ActiveAuctionsRow } from '../components/ui/HomeAuctionsRow';
import SearchResultsView from '../components/ui/SearchResultsView';
import UseResearchResults from './hooks/useResearchResults';
import useCollections from './hooks/useCollections';
import useLoadNFTs from './hooks/useNFTs';

const HomeList = ({
  renderItem,
  renderCollections,
}: {
  renderItem: (item) => JSX.Element;
  renderCollections: (item) => JSX.Element;
}) => {
  const { t } = useTranslation();
  const [nftDataList, setNftDataList] = useRecoilState(allNftDataState);
  const [collectionList, setCollectionList] = useRecoilState(
    allCollectionDataState,
  );
  const searchResultList = useRecoilValue(searchStoreState);
  const [listLoading, setListLoading] = useRecoilState(listLoadingState);
  const [categoryKey, setCategoryKey] = useRecoilState(
    currentSelectedCategoryState,
  );

  const initialTopTradersLoad = useRecoilValue<InitialTopTradersLoad>(
    allTopTradersState,
  );

  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [filterCModalVisible, setFilterCModalVisible] = useState(false);

  const [sorterQueryState, setSorterQueryState] = useRecoilState(
    sorterQueryRecoilState,
  );
  const setDropDetailState = useSetRecoilState(dropDetailDataState);
  const setSorterDropQueryState = useSetRecoilState(sorterQueryDropRecoilState);
  const [inSearchMode, setInSearchMode] = useRecoilState(searchModeState);

  const onLoadMoreSearchResults = UseResearchResults();
  const {
    onLoadMore,
    shouldShowLoadMoreButton,
    hasMore,
    loading,
  } = useLoadNFTs();
  const {
    onLoadMoreCollections,
    shouldShowLoadMoreCollectionsButton,
    showEndMessage,
  } = useCollections();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useEffect(() => {
    setListLoading(false);
    setSorterDropQueryState({
      inLoadingState: false,
      queryOrder: null,
    });
  }, []);

  setDropDetailState(null);

  async function onCategoryClicked(category: string) {
    setListLoading(true);

    setSorterQueryState({
      inLoadingState: false,
      queryOrder: null,
    });

    const processedKey = processCategory(category);
    const newCategoryKey = processedKey === 'all' ? '' : processedKey;
    setCategoryKey(newCategoryKey);

    if (newCategoryKey === 'collections') {
      const categoryCollections = await storageService.findAllCollections();
      setCollectionList({
        ...collectionList,
        collections: categoryCollections.collections,
      });
    } else {
      const categoryNfts = await storageService.findAllNfts(
        isOnMobile() ? 8 : 12,
        newCategoryKey,
      );
      setNftDataList({
        ...nftDataList,
        nfts: categoryNfts.nfts,
      });
    }

    await sleep(2_000);

    setListLoading(false);
  }

  function onFilterClicked() {
    setCategoryKey(categoryKey === 'collections' ? 'collections' : '');
    setFilterCModalVisible(categoryKey === 'collections');
    setFilterModalVisible(categoryKey !== 'collections');
  }

  const CategoriesListRow = ({
    categorySelected,
  }: {
    categorySelected: string;
  }) => (
    <div className="nfts-filter-row">
      <ul>
        <li
          key="filter"
          onClick={onFilterClicked}
          onKeyDown={onFilterClicked}
          role="presentation"
          tabIndex={-120}
          className="nft-category-filter"
        >
          <div className="nft-category-text">
            <img
              src={FilterIcon}
              alt="Filter Icon"
              height={42}
              width={42}
              role="presentation"
              className=""
            />
          </div>
        </li>

        {nftDataList.categories ? (
          nftDataList.categories.map((category, index) => {
            const processedCategory = processCategory(category);
            const caseForAllAssets =
              processedCategory === 'all' && categorySelected === '';
            return (
              <li
                className={
                  processedCategory === categorySelected || caseForAllAssets
                    ? 'nft-category nft-category-selected'
                    : 'nft-category'
                }
                key={category}
                onClick={() => onCategoryClicked(category)}
                onKeyDown={() => null}
                role="presentation"
                tabIndex={index}
              >
                <div className="nft-category-text">
                  {t(category) || category}
                </div>
              </li>
            );
          })
        ) : (
          <div />
        )}
      </ul>
    </div>
  );

  function VerifiedArtists() {
    if (
      !initialTopTradersLoad.hasLoaded ||
      !initialTopTradersLoad.topTraders ||
      !initialTopTradersLoad.topTraders.verified ||
      initialTopTradersLoad.topTraders.verified.length === 0
    ) {
      return <div />;
    }

    return (
      <div>
        <div className="top-trader-title">{t('Top Artists')} 🌈</div>
        <div className="nfts-filter-row">
          <ul>
            {initialTopTradersLoad.hasLoaded ? (
              initialTopTradersLoad.topTraders.verified.map((trader, index) => (
                <li key={trader.walletAddress} className="top-trader-item">
                  <TopTrader trader={trader} position={index + 1} />
                </li>
              ))
            ) : (
              <div />
            )}
          </ul>
        </div>
      </div>
    );
  }

  const SpecialDropsRow = () => {
    if (!initialTopTradersLoad.hasLoaded) {
      return <div />;
    }

    if (
      initialTopTradersLoad.hasLoaded &&
      !initialTopTradersLoad?.topTraders?.specialDrops?.length
    ) {
      return <div />;
    }

    const sliderRef = useRef<HTMLDivElement>(null);
    const nextSlide = () => {
      if (sliderRef) {
        sliderRef.current?.scrollBy(300, 0);
      }
    };

    const prevSlide = () => {
      if (sliderRef) {
        sliderRef.current.scrollBy(-330, 0);
      }
    };
    return (
      <div>
        <div className="top-trader-title">{t('Exclusive Drops 🔥')} </div>
        <div style={{ height: '8px' }} />
        <div className="nfts-filter-row-wrapper">
          <div
            className="nfts-top-trader-row"
            style={{
              width: '100%',
            }}
          >
            <div
              onKeyDown={prevSlide}
              role="presentation"
              tabIndex={-50}
              className="top-trader-arrow left-arrow"
              onClick={prevSlide}
              style={{
                display: `${
                  initialTopTradersLoad.topTraders.specialDrops
                    .filter((drop) => drop.published)
                    .map((data) => SpecialDropToDropCardData(data)).length <=
                    4 ||
                  isOnMobile() ||
                  isOnTablet()
                    ? 'none'
                    : 'block'
                }`,
              }}
            >
              <ArrowLeft />
            </div>
            <div
              onKeyDown={prevSlide}
              role="presentation"
              tabIndex={-50}
              className="top-trader-arrow right-arrow"
              onClick={nextSlide}
              style={{
                display: `${
                  initialTopTradersLoad.topTraders.specialDrops
                    .filter((drop) => drop.published)
                    .map((data) => SpecialDropToDropCardData(data)).length <=
                    4 ||
                  isOnMobile() ||
                  isOnTablet()
                    ? 'none'
                    : 'block'
                }`,
              }}
            >
              <ArrowRight />
            </div>
            <div ref={sliderRef} className="exclusive-drop-slider">
              {initialTopTradersLoad.topTraders.specialDrops
                .filter((drop) => drop.published)
                .map((data) => SpecialDropToDropCardData(data))
                .map((data) => (
                  <div key={data.dropTitle} className="drop-slider">
                    <DropCard dropCardData={data} />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div style={{ height: '30px' }} />
      </div>
    );
  };

  async function onExploreMarketClicked() {
    await sleep(100);
    setInSearchMode(false);
  }

  function ListViewComponent() {
    // When search is run and no results is found
    if (
      inSearchMode &&
      searchResultList.hasRunSearch &&
      !searchResultList.searching &&
      searchResultList.results.collections.length > 1 &&
      searchResultList.results.items.length > 1 &&
      searchResultList.results.users.length > 1
    ) {
      return (
        <EmptyNftListComponent
          onExploreMarketClicked={onExploreMarketClicked}
        />
      );
    }

    if (inSearchMode) {
      // The result page
      return (
        <SearchResultsView onLoadMoreSearchResults={onLoadMoreSearchResults} />
      );
    }

    return (
      <ListViewBuilder
        items={nftDataList.nfts}
        renderItem={renderItem}
        hasMore={!hasMore}
        infiniteScroll={nftDataList.enableInfiniteScroll}
        showLoadMoreButton={shouldShowLoadMoreButton}
        loading={listLoading || sorterQueryState.inLoadingState}
        loadingMore={loading}
        onLoadMore={onLoadMore}
      />
    );
  }

  return (
    <div className="nft-list">
      {!inSearchMode ? <VerifiedArtists /> : <div />}
      {!inSearchMode ? <SpecialDropsRow /> : <div />}

      {!inSearchMode ? <ActiveAuctionsRow /> : <div />}

      <FilterModal
        visible={filterModalVisible}
        onCancel={() => setFilterModalVisible(false)}
      />
      <FilterCollectionModal
        visible={filterCModalVisible}
        onCancel={() => setFilterCModalVisible(false)}
      />
      <ExploreTitle inSearchMode={inSearchMode} />
      {!inSearchMode ? (
        <CategoriesListRow categorySelected={categoryKey} />
      ) : (
        <div />
      )}

      {categoryKey === 'collections' ? (
        <ListViewBuilder
          onLoadMore={onLoadMoreCollections}
          showLoadMoreButton={shouldShowLoadMoreCollectionsButton}
          loading={listLoading || sorterQueryState.inLoadingState || loading}
          items={collectionList.collections}
          renderItem={renderCollections}
          showEndMessage={showEndMessage}
        />
      ) : (
        <ListViewComponent />
      )}
    </div>
  );
};

const Home = () => {
  const nftLoad = useRecoilValue(allNftDataState);

  function HomePageView() {
    if (!nftLoad || !nftLoad.nfts) {
      return <UnderMaintenance />;
    }

    return (
      <HomeList
        renderItem={(item) => (
          <List.Item>
            <NftCard nftCardData={NftModelToCardData(item)} />
          </List.Item>
        )}
        renderCollections={(collection) => (
          <List.Item>
            <CollectionCard
              collectionCardData={CollectionModelToCardData(collection)}
            />
          </List.Item>
        )}
      />
    );
  }

  return (
    <div>
      <div className="home-content">
        {!nftLoad.hasLoaded ? (
          <div className="home-init-spinner">
            <Spin size="large" />
          </div>
        ) : (
          HomePageView()
        )}
      </div>
    </div>
  );
};
export default Home;
