import React, { CSSProperties } from 'react';
import Router from 'next/router';
import { DropType, SpecialDropData } from '../../lib/models/NftModel';

const dropStyle = (bgImage): CSSProperties => ({
  paddingTop: '100%',
  objectFit: 'cover',
  borderRadius: '20px',
  background: `linear-gradient(196.94deg, rgba(0, 0, 0, 0) 42.78%, rgba(0, 0, 0, 0.8) 88.32%), url(${bgImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
});

export const DropCard = ({
  dropCardData,
}: {
  dropCardData: SpecialDropData;
}) => {
  const isCampaignDrop =
    dropCardData.dropType &&
    dropCardData.dropType === DropType.CAMPAIGN &&
    dropCardData.dropCampaignUrl;

  async function onCardClicked() {
    // Handle cases for campaigns drops
    if (isCampaignDrop) {
      await Router.push(dropCardData.dropCampaignUrl);
      return;
    }

    await Router.push(`/drop/${dropCardData.ID}`);
  }

  return (
    <div
      className="special-drop-card"
      onClick={onCardClicked}
      onKeyDown={onCardClicked}
      role="presentation"
    >
      <div className="drop-img-div">
        <div style={dropStyle(dropCardData.dropImage)} />
        {!isCampaignDrop && (
          <p className="drop-item-name">{dropCardData.dropTitle}</p>
        )}
      </div>
    </div>
  );
};

export const DropCardListItem = ({
  dropCardData,
}: {
  dropCardData: SpecialDropData;
}) => {
  const isCampaignDrop =
    dropCardData.dropType &&
    dropCardData.dropType === DropType.CAMPAIGN &&
    dropCardData.dropCampaignUrl;

  async function onCardClicked() {
    // Handle cases for campaigns drops
    if (isCampaignDrop) {
      await Router.push(dropCardData.dropCampaignUrl);
      return;
    }

    await Router.push(`/drop/${dropCardData.ID}`);
  }

  return (
    <div
      className="special-drop-card-item"
      onClick={onCardClicked}
      onKeyDown={onCardClicked}
      role="presentation"
    >
      <div className="drop-img-div-item">
        <div style={dropStyle(dropCardData.dropImage)} />
        {!isCampaignDrop && (
          <p className="drop-item-name" style={{ paddingRight: '25px' }}>
            {dropCardData.dropTitle}
          </p>
        )}
      </div>
    </div>
  );
};
