import * as Sentry from '@sentry/nextjs';
import {
  connect,
  StringCodec,
  NatsConnection,
} from '../node_modules/nats.ws/lib/src/mod.js';

export const decodeNatMessage = (message: Uint8Array) => {
  const decoded = StringCodec().decode(message);
  try {
    return JSON.parse(decoded);
  } catch (e) {
    return decoded;
  }
};

export const connectNatServer = async (): Promise<null | NatsConnection> => {
  try {
    const nc = await connect({
      servers: `${
        process.env.NEXT_PUBLIC_NATS_SERVER || 'ws://nats.airnfts.com:5000'
      }`,
    });
    return nc;
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};
