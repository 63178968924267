import { message } from 'antd';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { isOnMobile, sleep } from '../../lib/Utils';
import { allCollectionDataState } from '../../recoil/atoms';
import { storageService } from '../../lib/db/StorageService';

const useCollections = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [collectionList, setCollectionList] = useRecoilState(
    allCollectionDataState,
  );

  const [
    shouldShowLoadMoreCollectionsButton,
    setShouldShowLoadMoreCollectionsButton,
  ] = useState(!loading && showLoadMore);

  const [showEndMessage, setShowEndMessage] = useState(false);

  const onLoadMoreCollections = async () => {
    setLoading(true);

    const currentCollectionsLength = collectionList.collections.length;

    const nextFetchSize = isOnMobile()
      ? currentCollectionsLength + 6
      : currentCollectionsLength + 8;

    const loadCollectionData = await storageService.findAllCollections(
      nextFetchSize,
    );

    const moreCollections = loadCollectionData.collections;

    if (
      moreCollections?.length > 0 &&
      collectionList?.collections?.length >= moreCollections?.length
    ) {
      message.warning(t('You have loaded all items'));
      setLoading(false);
      setShowLoadMore(false);
      setShouldShowLoadMoreCollectionsButton(false);
      setShowEndMessage(true);
      return;
    }

    if (moreCollections.length > 0) {
      setCollectionList({
        ...collectionList,
        collections: moreCollections,
      });

      window.dispatchEvent(new Event('resize'));
    }

    await sleep(2_000);

    setLoading(false);
  };

  return {
    loading,
    onLoadMoreCollections,
    shouldShowLoadMoreCollectionsButton,
    showEndMessage,
  };
};

export default useCollections;
