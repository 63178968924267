import React from 'react';
import { useRecoilState } from 'recoil';
import {
  allCollectionDataState,
  sorterQueryRecoilState,
} from '../../recoil/atoms';
import { isOnMobile, sleep } from '../../lib/Utils';
import { storageService } from '../../lib/db/StorageService';
import { FilterModal } from './FilterModal';
import { IRadioOption } from '../../lib/@Types';

const options = [
  { label: 'Default', value: 'default' },
  { label: 'Recently Added', value: 'recent' },
  { label: 'Oldest', value: 'oldest' },
  { label: 'Lowest Price', value: 'cheapestC' },
  { label: 'Highest Price', value: 'expensiveC' },
] as IRadioOption[];

export const FilterCollectionModal = ({
  visible,
  onCancel,
}: {
  visible: boolean;
  onCancel: () => void;
}) => {
  const [collectionList, setCollectionList] = useRecoilState(
    allCollectionDataState,
  );

  const [, setSorterQueryState] = useRecoilState(sorterQueryRecoilState);

  const onFilter = async (sorterQuery) => {
    setSorterQueryState({
      inLoadingState: true,
      queryOrder: sorterQuery,
    });

    const categoryCollections = await storageService.findAllCollections(
      isOnMobile() ? 8 : 12,
      sorterQuery,
    );

    setCollectionList({
      ...collectionList,
      collections: categoryCollections.collections,
    });

    await sleep(2_000);

    setSorterQueryState({
      queryOrder: sorterQuery,
      inLoadingState: false,
    });
  };

  return (
    <FilterModal
      visible={visible}
      options={options}
      onCancel={onCancel}
      onFilter={onFilter}
    />
  );
};
