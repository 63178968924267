import { useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isOnMobile } from '../../lib/Utils';
import { storageService } from '../../lib/db/StorageService';
import {
  allAuctionsDataState,
  allNftDataState,
  currentSelectedCategoryState,
  searchModeState,
  sorterQueryRecoilState,
} from '../../recoil/atoms';

const useLoadNFTs = () => {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [nftDataList, setNftDataList] = useRecoilState(allNftDataState);
  const [auctionList, setAuctionList] = useRecoilState(allAuctionsDataState);
  const categoryKey = useRecoilValue(currentSelectedCategoryState);
  const sorterQueryState = useRecoilValue(sorterQueryRecoilState);
  const inSearchMode = useRecoilValue(searchModeState);

  const page = useRef(1);

  const shouldShowLoadMoreButton =
    !inSearchMode &&
    !loading &&
    showLoadMore &&
    nftDataList &&
    nftDataList.nfts &&
    nftDataList.nfts.length > 0;

  const shouldShowLoadMoreAuctionsButton =
    !inSearchMode &&
    !loading &&
    showLoadMore &&
    auctionList &&
    auctionList.auctions &&
    auctionList.auctions.length > 0;

  const onLoadMore = async (
    category = categoryKey,
    loadNFT: boolean = true,
  ) => {
    setLoading(true);
    page.current += 1;
    const limit = isOnMobile() ? 6 : 12;

    const moreNfts = [...nftDataList.nfts];
    const moreAuctions = [...auctionList.auctions];

    if (loadNFT) {
      const loadNftData = await storageService.findAllNfts(
        limit,
        category,
        sorterQueryState.queryOrder,
        page.current,
      );

      setHasMore(!loadNftData.hasMore);

      if (!loadNftData.hasMore) {
        setLoading(false);
        setShowLoadMore(false);
      }

      if (loadNftData.nfts?.length > 0) {
        moreNfts.push(...loadNftData.nfts);

        setNftDataList({
          ...nftDataList,
          nfts: [...new Set(moreNfts.map((nft) => nft.id))].map((id) =>
            moreNfts.find((nft) => nft.id === id),
          ),
          enableInfiniteScroll: true,
        });

        window.dispatchEvent(new Event('resize'));
      }

      if (loadNftData.error) {
        setLoading(false);
      }
    } else {
      const loadAuctionData = await storageService.findAllNfts(
        limit,
        category,
        null,
        page.current,
      );

      setHasMore(!loadAuctionData.hasMore);

      if (!loadAuctionData.hasMore) {
        setLoading(false);
        setShowLoadMore(false);
      }

      if (loadAuctionData.nfts?.length > 0) {
        moreAuctions.push(...loadAuctionData.nfts);

        setAuctionList({
          ...auctionList,
          auctions: [
            ...new Set(moreAuctions.map((auction) => auction.id)),
          ].map((id) => moreAuctions.find((auction) => auction.id === id)),
        });

        window.dispatchEvent(new Event('resize'));
      }

      if (loadAuctionData.error) {
        setLoading(false);
      }
    }

    setLoading(false);
  };

  return {
    onLoadMore,
    shouldShowLoadMoreButton,
    shouldShowLoadMoreAuctionsButton,
    loading,
    hasMore,
  };
};

export default useLoadNFTs;
